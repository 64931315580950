import React from 'react'

const Accomplishments = () => {
  return (
    <section id='accomplishments'>

    </section>
  )
}

export default Accomplishments;